var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "a-form",
                {
                  staticClass: "table-head-ground",
                  attrs: { layout: "inline" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "table-layer" },
                    [
                      _c(
                        "a-form-item",
                        {
                          staticClass: "table-head-layout",
                          staticStyle: {
                            "max-width": "350px",
                            "min-width": "300px",
                          },
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "a-range-picker",
                            {
                              attrs: {
                                "show-time": { format: "HH:mm:ss" },
                                format: "YYYY-MM-DD HH:mm:ss",
                                "disabled-date": _vm.disabledDate,
                              },
                              on: { change: _vm.onChange },
                            },
                            [
                              _c("a-icon", {
                                attrs: { slot: "suffixIcon", type: "sync" },
                                slot: "suffixIcon",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("jeepay-text-up", {
                        attrs: {
                          placeholder: "支付订单号",
                          msg: _vm.searchData.payOrderId,
                        },
                        model: {
                          value: _vm.searchData.payOrderId,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchData, "payOrderId", $$v)
                          },
                          expression: "searchData.payOrderId",
                        },
                      }),
                      _c("jeepay-text-up", {
                        attrs: {
                          placeholder: "商户订单号",
                          msg: _vm.searchData.mchOrderNo,
                        },
                        model: {
                          value: _vm.searchData.mchOrderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchData, "mchOrderNo", $$v)
                          },
                          expression: "searchData.mchOrderNo",
                        },
                      }),
                      _c("jeepay-text-up", {
                        attrs: {
                          placeholder: "应用AppId",
                          msg: _vm.searchData.appId,
                        },
                        model: {
                          value: _vm.searchData.appId,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchData, "appId", $$v)
                          },
                          expression: "searchData.appId",
                        },
                      }),
                      _c(
                        "a-form-item",
                        {
                          staticClass: "table-head-layout",
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                placeholder: "支付状态",
                                "default-value": "",
                              },
                              model: {
                                value: _vm.searchData.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchData, "state", $$v)
                                },
                                expression: "searchData.state",
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _c("a-select-option", { attrs: { value: "0" } }, [
                                _vm._v("订单生成"),
                              ]),
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("支付中"),
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("支付成功"),
                              ]),
                              _c("a-select-option", { attrs: { value: "3" } }, [
                                _vm._v("支付失败"),
                              ]),
                              _c("a-select-option", { attrs: { value: "4" } }, [
                                _vm._v("已撤销"),
                              ]),
                              _c("a-select-option", { attrs: { value: "5" } }, [
                                _vm._v("已退款"),
                              ]),
                              _c("a-select-option", { attrs: { value: "6" } }, [
                                _vm._v("订单关闭"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.$access("ENT_PAY_ORDER_SEARCH_PAY_WAY")
                        ? _c(
                            "a-form-item",
                            {
                              staticClass: "table-head-layout",
                              attrs: { label: "" },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  attrs: {
                                    placeholder: "支付方式",
                                    "default-value": "",
                                  },
                                  model: {
                                    value: _vm.searchData.wayCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchData, "wayCode", $$v)
                                    },
                                    expression: "searchData.wayCode",
                                  },
                                },
                                [
                                  _c(
                                    "a-select-option",
                                    { attrs: { value: "" } },
                                    [_vm._v("全部")]
                                  ),
                                  _vm._l(_vm.payWayList, function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.wayCode,
                                        attrs: { value: item.wayCode },
                                      },
                                      [_vm._v(" " + _vm._s(item.wayName) + " ")]
                                    )
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-item",
                        {
                          staticClass: "table-head-layout",
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              attrs: {
                                placeholder: "分账状态",
                                "default-value": "",
                              },
                              model: {
                                value: _vm.searchData.divisionState,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchData, "divisionState", $$v)
                                },
                                expression: "searchData.divisionState",
                              },
                            },
                            [
                              _c("a-select-option", { attrs: { value: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _c("a-select-option", { attrs: { value: "0" } }, [
                                _vm._v("未发生分账"),
                              ]),
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("等待分账任务处理"),
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("分账处理中"),
                              ]),
                              _c("a-select-option", { attrs: { value: "3" } }, [
                                _vm._v("分账任务已结束（状态请看分账记录）"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "table-page-search-submitButtons" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "search",
                                loading: _vm.btnLoading,
                              },
                              on: { click: _vm.queryFunc },
                            },
                            [_vm._v("搜索")]
                          ),
                          _c(
                            "a-button",
                            {
                              staticStyle: { "margin-left": "8px" },
                              attrs: { icon: "reload" },
                              on: { click: () => (this.searchData = {}) },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c("JeepayTable", {
            ref: "infoTable",
            attrs: {
              initData: true,
              reqTableDataFunc: _vm.reqTableDataFunc,
              tableColumns: _vm.tableColumns,
              searchData: _vm.searchData,
              rowKey: "payOrderId",
              scrollX: 1000,
            },
            on: {
              btnLoadClose: function ($event) {
                _vm.btnLoading = false
              },
            },
            scopedSlots: _vm._u([
              {
                key: "amountSlot",
                fn: function ({ record }) {
                  return [_c("b", [_vm._v("￥" + _vm._s(record.amount / 100))])]
                },
              },
              {
                key: "stateSlot",
                fn: function ({ record }) {
                  return [
                    _c(
                      "a-tag",
                      {
                        key: record.state,
                        attrs: {
                          color:
                            record.state === 0
                              ? "blue"
                              : record.state === 1
                              ? "orange"
                              : record.state === 2
                              ? "green"
                              : "volcano",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              record.state === 0
                                ? "订单生成"
                                : record.state === 1
                                ? "支付中"
                                : record.state === 2
                                ? "支付成功"
                                : record.state === 3
                                ? "支付失败"
                                : record.state === 4
                                ? "已撤销"
                                : record.state === 5
                                ? "已退款"
                                : record.state === 6
                                ? "订单关闭"
                                : "未知"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "divisionStateSlot",
                fn: function ({ record }) {
                  return [
                    record.divisionState == 0
                      ? _c("a-tag", { attrs: { color: "blue" } }, [
                          _vm._v("未发生分账"),
                        ])
                      : record.divisionState == 1
                      ? _c("a-tag", { attrs: { color: "orange" } }, [
                          _vm._v("待分账"),
                        ])
                      : record.divisionState == 2
                      ? _c("a-tag", { attrs: { color: "red" } }, [
                          _vm._v("分账处理中"),
                        ])
                      : record.divisionState == 3
                      ? _c("a-tag", { attrs: { color: "green" } }, [
                          _vm._v("任务已结束"),
                        ])
                      : _c("a-tag", { attrs: { color: "#f50" } }, [
                          _vm._v("未知"),
                        ]),
                  ]
                },
              },
              {
                key: "opSlot",
                fn: function ({ record }) {
                  return [
                    _c(
                      "JeepayTableColumns",
                      [
                        _vm.$access("ENT_PAY_ORDER_VIEW")
                          ? _c(
                              "a-button",
                              {
                                attrs: { type: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.detailFunc(record.payOrderId)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            )
                          : _vm._e(),
                        _vm.$access("ENT_PAY_ORDER_REFUND")
                          ? _c(
                              "a-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      record.state === 2 &&
                                      record.refundState !== 2,
                                    expression:
                                      "(record.state === 2 && record.refundState !== 2)",
                                  },
                                ],
                                staticStyle: { color: "red" },
                                attrs: { type: "link" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openFunc(
                                      record,
                                      record.payOrderId
                                    )
                                  },
                                },
                              },
                              [_vm._v("退款")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("refund-modal", {
        ref: "refundModalInfo",
        attrs: { callbackFunc: _vm.searchFunc },
      }),
      [
        _c(
          "a-drawer",
          {
            attrs: {
              width: "50%",
              placement: "right",
              closable: true,
              visible: _vm.visible,
              title: _vm.visible === true ? "订单详情" : "",
            },
            on: { close: _vm.onClose },
          },
          [
            _c(
              "a-row",
              { attrs: { justify: "space-between", type: "flex" } },
              [
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "服务商号" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.isvNo) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "支付订单号" } },
                          [
                            _c("a-tag", { attrs: { color: "purple" } }, [
                              _vm._v(
                                " " + _vm._s(_vm.detailData.payOrderId) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "商户号" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.mchNo) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "商户订单号" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.detailData.mchOrderNo) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "商户名称" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.mchName) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "应用APPID" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.appId) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "订单状态" } },
                          [
                            _c(
                              "a-tag",
                              {
                                attrs: {
                                  color:
                                    _vm.detailData.state === 0
                                      ? "blue"
                                      : _vm.detailData.state === 1
                                      ? "orange"
                                      : _vm.detailData.state === 2
                                      ? "green"
                                      : "volcano",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.detailData.state === 0
                                        ? "订单生成"
                                        : _vm.detailData.state === 1
                                        ? "支付中"
                                        : _vm.detailData.state === 2
                                        ? "支付成功"
                                        : _vm.detailData.state === 3
                                        ? "支付失败"
                                        : _vm.detailData.state === 4
                                        ? "已撤销"
                                        : _vm.detailData.state === 5
                                        ? "已退款"
                                        : _vm.detailData.state === 6
                                        ? "订单关闭"
                                        : "未知"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "支付金额" } },
                          [
                            _c("a-tag", { attrs: { color: "green" } }, [
                              _vm._v(
                                " " + _vm._s(_vm.detailData.amount / 100) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "手续费" } },
                          [
                            _c("a-tag", { attrs: { color: "pink" } }, [
                              _vm._v(_vm._s(_vm.detailData.mchFeeAmount / 100)),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "商家费率" } },
                          [
                            _vm._v(
                              _vm._s(
                                (_vm.detailData.mchFeeRate * 100).toFixed(2)
                              ) + "%"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "支付错误码" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.errCode) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "支付错误描述" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.errMsg) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "订单失效时间" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.detailData.expiredTime) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "支付成功时间" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.detailData.successTime) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "创建时间" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.createdAt) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "更新时间" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.updatedAt) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("a-divider"),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "商品标题" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.subject) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "商品描述" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.body) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "接口代码" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.ifCode) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "货币代码" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.currency) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "支付方式" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.wayCode) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "客户端IP" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.clientIp) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "用户标识" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.detailData.channelUser) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "渠道订单号" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.detailData.channelOrderNo) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "异步通知地址" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.notifyUrl) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "页面跳转地址" } },
                          [_vm._v(" " + _vm._s(_vm.detailData.returnUrl) + " ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "退款次数" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.detailData.refundTimes) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "退款总额" } },
                          [
                            _vm.detailData.refundAmount
                              ? _c("a-tag", { attrs: { color: "cyan" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.detailData.refundAmount / 100
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("a-divider"),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "订单分账模式" } },
                          [
                            _vm.detailData.divisionMode == 0
                              ? _c("span", [_vm._v("该笔订单不允许分账")])
                              : _vm.detailData.divisionMode == 1
                              ? _c("span", [
                                  _vm._v("支付成功按配置自动完成分账"),
                                ])
                              : _vm.detailData.divisionMode == 2
                              ? _c("span", [
                                  _vm._v("商户手动分账(解冻商户金额)"),
                                ])
                              : _c("span", [_vm._v("未知")]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "分账状态" } },
                          [
                            _vm.detailData.divisionState == 0
                              ? _c("a-tag", { attrs: { color: "blue" } }, [
                                  _vm._v("未发生分账"),
                                ])
                              : _vm.detailData.divisionState == 1
                              ? _c("a-tag", { attrs: { color: "orange" } }, [
                                  _vm._v("待分账"),
                                ])
                              : _vm.detailData.divisionState == 2
                              ? _c("a-tag", { attrs: { color: "red" } }, [
                                  _vm._v("分账处理中"),
                                ])
                              : _vm.detailData.divisionState == 3
                              ? _c("a-tag", { attrs: { color: "green" } }, [
                                  _vm._v("任务已结束"),
                                ])
                              : _c("a-tag", { attrs: { color: "#f50" } }, [
                                  _vm._v("未知"),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { sm: 12 } },
                  [
                    _c(
                      "a-descriptions",
                      [
                        _c(
                          "a-descriptions-item",
                          { attrs: { label: "最新分账发起时间" } },
                          [_vm._v(_vm._s(_vm.detailData.divisionLastTime))]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("a-divider"),
            _c(
              "a-row",
              { attrs: { justify: "start", type: "flex" } },
              [
                _c(
                  "a-col",
                  { attrs: { sm: 24 } },
                  [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "扩展参数" } },
                      [
                        _c("a-input", {
                          staticStyle: { height: "100px", color: "black" },
                          attrs: { type: "textarea", disabled: "disabled" },
                          model: {
                            value: _vm.detailData.extParam,
                            callback: function ($$v) {
                              _vm.$set(_vm.detailData, "extParam", $$v)
                            },
                            expression: "detailData.extParam",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }